import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container templates" }
const _hoisted_2 = { class: "d-flex w-100 justify-content-end page-heading-toolbar" }
const _hoisted_3 = { class: "page-heading-toolbar--col" }
const _hoisted_4 = { class: "page-heading-toolbar--col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateLocalizationModal = _resolveComponent("CreateLocalizationModal")!
  const _component_DeleteBrandDialog = _resolveComponent("DeleteBrandDialog")!
  const _component_BtnContent = _resolveComponent("BtnContent")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_PageMainHeading = _resolveComponent("PageMainHeading")!
  const _component_LocalizationsTable = _resolveComponent("LocalizationsTable")!
  const _component_AppTableController = _resolveComponent("AppTableController")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CreateLocalizationModal, {
      key: "1",
      opened: _ctx.updateModalOpened,
      localization: _ctx.editData?.id ? _ctx.editData : undefined,
      "action-loading": _ctx.modalActionLoading,
      "window-loading": false,
      onSetModalLoading: _ctx.setModalLoading,
      onUpdateIcon: _ctx.saveIcon,
      onSave: _ctx.saveUpdates,
      onHide: _ctx.endUpdating
    }, null, 8, ["opened", "localization", "action-loading", "onSetModalLoading", "onUpdateIcon", "onSave", "onHide"]),
    _createVNode(_component_DeleteBrandDialog, {
      title: _ctx.editData?.full_name,
      opened: _ctx.deletePageOpened,
      loading: _ctx.modalActionLoading,
      "is-show": false,
      onHide: _ctx.endUpdating,
      onConfirm: _ctx.onDeleteConfirm,
      onCancel: _ctx.endUpdating
    }, null, 8, ["title", "opened", "loading", "onHide", "onConfirm", "onCancel"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageMainHeading, { title: "Localizations" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_AppButton, {
                  type: "secondary",
                  size: "lg",
                  onClick: _ctx.editLocalization
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BtnContent, {
                      icon: "plus",
                      label: "Add localization"
                    })
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_AppTableController, {
      "show-table": !!_ctx.localizations.length,
      "search-query": _ctx.searchQuery,
      "empty-button-text": "Add localization",
      "empty-key": "pages",
      "empty-icon": "pages",
      onEmptyButtonClick: _ctx.editLocalization
    }, {
      default: _withCtx(() => [
        (_ctx.localizations.length)
          ? (_openBlock(), _createBlock(_component_LocalizationsTable, {
              key: 0,
              "localizations-list": _ctx.localizations,
              "total-rows-count": _ctx.totalLocalizationsCount,
              "view-configuration": _ctx.viewConfiguration,
              onToggleEdit: _ctx.editLocalization,
              onDelete: _ctx.removeLocalization,
              onChangeViewConfiguration: _ctx.changeViewConfigurationHandler
            }, null, 8, ["localizations-list", "total-rows-count", "view-configuration", "onToggleEdit", "onDelete", "onChangeViewConfiguration"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["show-table", "search-query", "onEmptyButtonClick"])
  ], 64))
}