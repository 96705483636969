
import { defineComponent, PropType } from "vue";
import { IUpdateFaqListDto } from "@/api/services/locations/locationTypes";
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AutosizeTextarea from "@/components/Form/AutosizeTextarea.vue";

interface IUpdateFaqModalData extends IUpdateFaqListDto {
  valid: boolean;
}
export default defineComponent({
  components: {
    AppButton,
    AppInputsValidationProvider,
    Dialog,
    AppInput,
    AutosizeTextarea
  },

  props: {
    editData: { type: Object as PropType<IUpdateFaqModalData>, required: true },
    opened: { type: Boolean, default: false },
    isEditing: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },

  emits: {
    hide: null,
    submit: null
  },

  data() {
    return {
      title: "",
      description: "",
      valid: false
    } as IUpdateFaqModalData;
  },

  computed: {
    modalTitle() {
      return this.isEditing ? this.editData.title : "New Question";
    },

    buttonLabel() {
      return this.isEditing ? "Save" : "Add Question";
    }
  },

  watch: {
    editData: {
      immediate: true,
      handler(data: IUpdateFaqListDto) {
        if (data) {
          this.title = data.title;
          this.description = data.description;
        } else {
          this.title = "";
          this.description = "";
        }
      }
    }
  },

  methods: {
    changeErrorState(error: boolean) {
      this.valid = !error;
    },

    submitHandler() {
      const { title, description } = this;
      const data = {
        title,
        description
      };

      this.$emit("submit", data);
    }
  }
});
