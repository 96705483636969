import { tableLocationStatuses } from "@/api/services/packages/esim/location-packages/types";
import { ITableBodyRow } from "@/components/Table/ts/interfaces/TableStructure";
import { badgeSizes, badgeTypes } from "@/components/ui/AppBadge/interfaces";
import { sortOrders } from "@/enums/main/sortOrders";
import { useBaseTable } from "@/hooks/tables/useTable";
import { ILocationsStateLists, TLocationKeys } from "@/store/locations";
import { dateConverter } from "@/utills/dateConverter";
import { ILocationsCtx } from "../useLocations";

const initialSortConfiguration = {
  byName: {
    order: sortOrders.asc,
    keyName: "Title",
    keyNameInModel: "name"
  },

  byCreated: {
    order: sortOrders.turnOff,
    keyName: "Created date",
    keyNameInModel: "createdAt"
  }
};

export function useLocationsTable<T extends TLocationKeys>(
  ctx: ILocationsCtx<T>
): useBaseTable {
  return useBaseTable({
    initialSortOptions: initialSortConfiguration,
    initialViewConfiguration: ctx.viewConfiguration?.value || {
      page: 1,
      perPage: 1
    },
    model: ctx.locationsList,
    generateRows: <T extends TLocationKeys>(
      model: ILocationsStateLists[T]
    ): ITableBodyRow[] => {
      return model.map(
        ({ id, icon, name, createdAt, active }): ITableBodyRow => {
          return {
            id,
            cells: [
              {
                country: { name, icon }
              },

              {
                rightAlign: true,
                label: dateConverter(new Date(createdAt))
              },

              {
                rightAlign: true,
                badge: {
                  rounded: false,
                  size: badgeSizes.md,
                  type: active ? badgeTypes.success : badgeTypes.danger,
                  label: active
                    ? tableLocationStatuses.active
                    : tableLocationStatuses.inactive
                }
              },

              {
                userActions: true
              }
            ]
          };
        }
      );
    },

    onViewConfigurationChanged: ctx.onViewConfigChanged
  });
}
