import {
  ILocationViewConfig,
  TPackageLocation
} from "@/hooks/esim/location-packages/types/locationPackage.types";
import { TLocationName } from "@/hooks/esim/location-packages/modules/locationKeys";
import { IEsimDirectoryDefault } from "@/api/interfaces/esim-directory/common";
import {ICarrierRate} from "@/store/carriers";
import { ICountry } from "@/interfaces/ICountry";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import { IProviderType } from "@/hooks/esim/location-packages/types/useLocationPackageListType";
import { localizationsLocation } from "@/hooks/locations/new/modules/location.update";

export interface fetchLocationPackagesDto {
  locationType: TPackageLocation;
  params: ILocationViewConfig;
}

export interface fetchLocationPackagesRatesDto {
  locationType: TPackageLocation;
}

export interface IOperator {
  id: number;
  name: string;
  image: string;
  rate: number;
}

export enum tableLocationStatuses {
  active = "active",
  inactive = "inactive"
}

export interface IBaseLocation {
  id: number;
  active: boolean;
  name: string;
  icon: string;
  smallImage: string | null;
  bigImage: string | null;
  createdAt: string;
  updatedAt: string;
  isoCode?: string;
  popular?: boolean;
  titleSEO: string;
  descriptionSEO?: string;
  advantagesHeader?: string;
  advantagesTitle?: string;
  advantagesDescription?: string;
  keywords: string;
}

export interface IPlanShort {
  active: boolean;
  description: string;
  icon1: string;
  icon2: string;
  id: number;
  name: string;
  planType?: string;
}

export interface IPurchaseType {
  active: boolean;
  id: number;
  key: string;
  title: string;
}

export interface IBundle {
  id: number;
  i_product?: number;
  name: string;
  providerTypeId?: number;
}

export interface IResellerBundle {
  id: number;
  contract_status: string;
  contract_title: string;
  data_amount: string;
  data_unit: string;
  fullspeed_data_amount: string;
  is_country_specific: boolean;
  offering_status: string;
  offering_title: string;
  plan_code: string;
  timezone: string;
  unit_price: string;
  validity_amount: string;
  validity_unit: string;
  providerTypeId?: number;
}

export interface IBundlesProviders {
  id: number;
  name: string;
  multitelBundles?: IBundle[];
  resellerBundles?: IBundle[];
}
export interface fetchEsimBundlesDto extends ITableViewConfiguration {
  id: number;
}

export interface IValidity {
  active: boolean;
  id: number;
  period: number;
  title: string;
}

export interface IDataType {
  active: boolean;
  id: number;
  key: string;
  title: string;
  packages?: IPackage[];
}

export interface IBasePackage {
  id: number;
  title: string;
  bundleId: number;
  defaultPrice: number;
  discount: boolean;
  discountAmount: number;
  priceWithDiscount: number;
  commission: number;
  trafficAmount: IEsimDirectoryDefault;
  traffic: IEsimDirectoryDefault;
}

export interface IPackage extends IBasePackage {
  plan?: IPlanShort;
  purchaseType?: IPurchaseType;
  validity?: IValidity;
  dataType?: IDataType;
  country?: ICountry;
  region?: IRegion;
  subregion?: ISubregion;
  operators?: IOperator[];
  providerType: IProviderType;
}

export interface IPlan extends IPlanShort {
  id: number;
  name: string;
  planType: string;
  description: string;
  active: boolean;
  icon?: string;
  icon1: string;
  packages: IPackage[];
}

export interface IZone {
  id: number;
  title: string;
  key: string;
}

export interface INetworkGeneration {
  id: number;
  title: string;
  key: string;
  active: boolean;
}

export interface INetworkGenerationItem {
  id: number;
  active: boolean;
  networkGeneration: INetworkGeneration;
}

export interface IOperator {
  id: number;
  name: string;
  image: string;
  countryId: number;
  countryName?: string;
  netGens: INetworkGenerationItem[];
  rates: ICarrierRate[];
}

export interface IPlanOperator {
  id: number;
  active: boolean;
  operator: IOperator;
}

export interface ILocationPlan {
  active: boolean;
  id: number;
  plan: IPlan;
  zone?: IZone;
  discount: boolean;
  discountAmount: number;
  planOperators?: IPlanOperator[];
}

export interface IPackageCountry extends IBaseLocation {
  isoCode: string;
  popular: boolean;
  secondaryBigImage: string | null;
}

export interface ISubregionShort extends IBaseLocation {
  regionId: number;
  countries: IPackageCountry[];
  subregionPlans?: ILocationPlan[];
}

export interface ISubregion extends ISubregionShort, IBaseLocation {
  packages: IBasePackage[];
}
export interface IRegion extends IBaseLocation {
  packages: IBasePackage[];
}

export interface ILocationPackage {
  active: boolean;
  bigImage: string;
  smallImage: string;
  countryCount?: number;
  createdAt: string;
  updatedAt: string;
  isoCode?: string;

  secondaryBigImage?: string;
  popular?: boolean;
  icon: string;
  id: number;
  name: string;
  operatorsCount?: number;
  orderId?: number;
  regionPlans?: ILocationPlan[];
  countryPlans?: ILocationPlan[];
  subregionPlans?: ILocationPlan[];

  countries?: IPackageCountry[];
  subregions?: ISubregionShort[];

  updatedBy: {
    fullName: string;
    accessImage: string;
  } | null;
}

export interface IUpdateLocationPackageDto {
  // exist on edit:
  id?: number;

  // one of:
  country?: number;
  region?: number;
  subregion?: number;

  // one of:
  discountAmount: number;

  // required:
  title: string;
  bundleId: number;
  defaultPrice: number;
  // discount: boolean;
  discount: boolean;
  priceWithDiscount: number;
  validity: number;
  purchaseType: number;
  dataType: number;
  plan: number;
  commission: number;
  traffic: IEsimDirectoryDefault;
  providerTypeId: number;
}

export interface IRemoveLocationPackageDto {
  packageId: number;
  locationType: TLocationName;
}
