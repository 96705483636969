
import { ComputedRef, defineComponent, PropType, computed } from "vue";
import {
  ITableBodyRow,
  ITableHeadCell
} from "@/components/Table/ts/interfaces/TableStructure";
import { sortItem, sortOrders } from "@/enums/main/sortOrders";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import AppTable from "@/components/Table/ts/AppTable.vue";
import { useBaseTable } from "@/hooks/tables/useTable";
import { formatDate } from "@/hooks/payments/usePayments";
import { badgeTypes } from "@/components/ui/AppBadge/interfaces";
import { ILocalizations } from "@/api/interfaces/localizations/ILocalizations";
type tableHeadCells = ITableHeadCell[];

export type templatesTableSort = {
  bySubject: sortItem;
};

export default defineComponent({
  name: "LocalizationsTable",
  components: {
    AppTable
  },

  emits: {
    toggleEdit: null,
    delete: null,
    changeViewConfiguration: null
  },
  setup(props, { emit }) {
    const initialViewConfiguration = {
      page: 1,
      perPage: 10
    };

    const initialSortOptions: templatesTableSort = {
      bySubject: {
        order: sortOrders.turnOff,
        keyName: "subject",
        keyNameInModel: "subject"
      }
    };

    // rows actions
    const localizationEditHandler = (data: ILocalizations) => rowClickHandler(data);
    const localizationDeleteHandler = (id: number | undefined) => emit("delete", id);

    async function rowClickHandler(data: ILocalizations): Promise<void> {
      emit("toggleEdit", data);
    }

    // table model
    const localizations: ComputedRef<ILocalizations[]> = computed(
      () => props.localizationsList
    );

    const tableRefs = useBaseTable({
      initialViewConfiguration,
      initialSortOptions,
      model: localizations,
      generateRows(localizationsList: ILocalizations[]): ITableBodyRow[] {
        return localizations.value.map(
          ({
            id,
            short_name,
            createdAt,
            active,
            full_name,
            icon,
            ...res
          }: ILocalizations): ITableBodyRow => {
            let status = active ? "active" : "inactive";
            return {
              status,
              onClick: (): void => {
                localizationEditHandler({
                  id,
                  short_name,
                  createdAt,
                  active,
                  full_name,
                  icon,
                  ...res
                });
              },
              actions: [
                {
                  text: "Edit",
                  onClick: (): void => {
                    localizationEditHandler({
                      id,
                      short_name,
                      createdAt,
                      active,
                      full_name,
                      icon,
                      ...res
                    });
                  }
                },
                {
                  text: "Remove",
                  onClick: (): void => {
                    localizationDeleteHandler(id);
                  }
                }
              ],
              cells: [
                {
                  label: full_name,
                  country: { name: full_name, icon }
                },
                {
                  label: short_name
                },
                {
                  label: formatDate(createdAt)
                },
                {
                  badge: {
                    type: active
                      ? badgeTypes.lightSecondary
                      : badgeTypes.danger,

                    label: active ? "Active" : "Inactive"
                  }
                }
              ]
            };
          }
        );
      },
      onViewConfigurationChanged(
        viewConfiguration: ITableViewConfiguration
      ): void {
        // get data by parent component
        emit("changeViewConfiguration", viewConfiguration);
      }
    });

    const {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    } = tableRefs;

    return {
      rows,
      changeViewConfiguration,
      changeSorting,
      viewConfiguration,
      currentSort
    };
  },

  props: {
    totalRowsCount: {
      type: Number,
      required: true
    },

    localizationsList: {
      type: Array as PropType<ILocalizations[]>,
      required: true
    },

    parentViewConfiguration: {
      type: Object as PropType<ITableViewConfiguration>,
      default: () => ({
        page: 1,
        perPage: 10,
        search: "",
        sortField: "subject",
        sortType: "ASC"
      })
    }
  },

  computed: {
    tableHead(): tableHeadCells {
      const { bySubject } = this.currentSort;
      return [
        {
          size: "lg",
          label: "Full name",
          id: "full_name"
        },
        {
          size: "lg",
          label: "Short name",
          id: "short_name"
        },
        {
          size: "lg",
          label: "Created at",
          id: "createdAt"
        },
        {
          size: "sm",
          label: "Status",
          id: "pages-status"
        }
      ];
    }
  }
});
