import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AutosizeTextarea = _resolveComponent("AutosizeTextarea")!
  const _component_AppInputsValidationProvider = _resolveComponent("AppInputsValidationProvider")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    title: _ctx.title,
    opened: _ctx.opened,
    class: "create-location-modal",
    onHide: _ctx.hide
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[15] || (_cache[15] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("div", null, [
          _createVNode(_component_AppInputsValidationProvider, {
            "shake-tre": _ctx.disabledButton,
            onChangeErrorState: _ctx.onChangeErrorState
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AppInput, {
                id: "title-page",
                modelValue: _ctx.editData.h1,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editData.h1) = $event)),
                "input-type": "material",
                label: "H1",
                type: "text",
                name: "title-page",
                required: false
              }, null, 8, ["modelValue"]),
              _createVNode(_component_AutosizeTextarea, {
                id: "title-body",
                modelValue: _ctx.editData.body,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editData.body) = $event)),
                "input-type": "material",
                label: "Body",
                type: "text",
                "is-textarea": true,
                "is-auto-size": true,
                "textarea-required": false,
                name: "title-description"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_AutosizeTextarea, {
                id: "seo-block",
                modelValue: _ctx.editData.SEO_block,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editData.SEO_block) = $event)),
                "input-type": "material",
                label: "SEO block",
                type: "text",
                "is-textarea": true,
                "is-auto-size": true,
                "textarea-required": false,
                name: "title-description"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_AppInput, {
                id: "title-seo",
                modelValue: _ctx.editData.title,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editData.title) = $event)),
                "input-type": "material",
                label: "Title",
                type: "text",
                name: "title-seo",
                required: false
              }, null, 8, ["modelValue"]),
              _createVNode(_component_AutosizeTextarea, {
                id: "title-description",
                modelValue: _ctx.editData.description,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editData.description) = $event)),
                "input-type": "material",
                label: "Description",
                type: "text",
                "is-textarea": true,
                "is-auto-size": true,
                "textarea-required": false,
                name: "title-description"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_AutosizeTextarea, {
                id: "advantages-header",
                modelValue: _ctx.editData.advantagesHeader,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editData.advantagesHeader) = $event)),
                "input-type": "material",
                label: "Advantages header",
                type: "text",
                "is-textarea": true,
                "is-auto-size": true,
                "textarea-required": false,
                name: "advantages-header"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_AutosizeTextarea, {
                id: "advantages-title",
                modelValue: _ctx.editData.advantagesTitle,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editData.advantagesTitle) = $event)),
                "input-type": "material",
                label: "Advantages title",
                type: "text",
                "is-textarea": true,
                "is-auto-size": true,
                "textarea-required": false,
                name: "advantages-title"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_AutosizeTextarea, {
                id: "advantages-description",
                modelValue: _ctx.editData.advantagesDescription,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editData.advantagesDescription) = $event)),
                "input-type": "material",
                label: "Advantages description",
                type: "text",
                "is-textarea": true,
                "is-auto-size": true,
                "textarea-required": false,
                name: "advantages-description"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_AutosizeTextarea, {
                id: "advantages-advantagesBlokForget",
                modelValue: _ctx.editData.advantagesBlokForget,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editData.advantagesBlokForget) = $event)),
                "input-type": "material",
                label: "Advantages Forget blok",
                type: "text",
                "is-textarea": true,
                "is-auto-size": true,
                "textarea-required": false,
                name: "advantages-advantagesBlokForget"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_AutosizeTextarea, {
                id: "advantages-advantagesBlokEasy",
                modelValue: _ctx.editData.advantagesBlokEasy,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.editData.advantagesBlokEasy) = $event)),
                "input-type": "material",
                label: "Advantages Easy blok",
                type: "text",
                "is-textarea": true,
                "is-auto-size": true,
                "textarea-required": false,
                name: "advantages-advantagesBlokEasy"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_AutosizeTextarea, {
                id: "advantages-advantagesBlokConnect",
                modelValue: _ctx.editData.advantagesBlokConnect,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.editData.advantagesBlokConnect) = $event)),
                "input-type": "material",
                label: "Advantages Connect blok",
                type: "text",
                "is-textarea": true,
                "is-auto-size": true,
                "textarea-required": false,
                name: "advantages-advantagesBlokConnect"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_AutosizeTextarea, {
                id: "advantages-advantagesBlokEnjoy",
                modelValue: _ctx.editData.advantagesBlokEnjoy,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.editData.advantagesBlokEnjoy) = $event)),
                "input-type": "material",
                label: "Advantages Enjoy blok",
                type: "text",
                "is-textarea": true,
                "is-auto-size": true,
                "textarea-required": false,
                name: "advantages-advantagesBlokEnjoy"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_AutosizeTextarea, {
                id: "advantages-advantagesBlokKeep",
                modelValue: _ctx.editData.advantagesBlokKeep,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.editData.advantagesBlokKeep) = $event)),
                "input-type": "material",
                label: "Advantages Keep blok",
                type: "text",
                "is-textarea": true,
                "is-auto-size": true,
                "textarea-required": false,
                name: "advantages-advantagesBlokKeep"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_AutosizeTextarea, {
                id: "advantages-advantagesBlokRecive",
                modelValue: _ctx.editData.advantagesBlokRecive,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.editData.advantagesBlokRecive) = $event)),
                "input-type": "material",
                label: "Advantages Recive blok",
                type: "text",
                "is-textarea": true,
                "is-auto-size": true,
                "textarea-required": false,
                name: "advantages-advantagesBlokRecive"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_AppInput, {
                id: "Keywords-seo",
                modelValue: _ctx.editData.keywords,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.editData.keywords) = $event)),
                "input-type": "material",
                label: "Keywords",
                type: "text",
                name: "Keywords-seo",
                required: false
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["shake-tre", "onChangeErrorState"])
        ]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_AppButton, {
            disabled: !_ctx.valid,
            type: "secondary",
            size: "xl",
            loading: _ctx.loading,
            onClick: _ctx.submit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }, 8, ["disabled", "loading", "onClick"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "opened", "onHide"]))
}