
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppCheckbox from "@/components/Form/AppCheckbox.vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import { IAboutLocation } from "@/api/interfaces/about-locations/aboutLocation";
import AutosizeTextarea from "@/components/Form/AutosizeTextarea.vue";

export default defineComponent({
  name: "AboutLocationModal",

  components: {
    AutosizeTextarea,
    AppButton,
    AppInput,
    Dialog,
    AppInputsValidationProvider
  },
  emits: ["submit", "hide", "cancel"],

  props: {
    disabled: { type: Boolean, default: true },
    opened: { type: Boolean, default: true },
    buttonText: { type: String, required: false },
    canCancel: { type: Boolean, default: false },
    isShow: { type: Boolean, default: true },
    title: { type: String, default: "" },
    data: {
      type: Object as PropType<IAboutLocation>,
      required: false
    },
    loading: { type: Boolean, default: false }
  },
  data() {
    return {
      buttonTextUpdated: this.buttonText,
      valid: false,
      disabledButton: false,
      isChecked: false,
      editData: {} as IAboutLocation
    };
  },
  watch: {
    data(data: IAboutLocation): void {
      if (data) {
        this.editData = { ...data };
      } else {
        this.editData = {} as IAboutLocation;
      }
    },
    editData: {
      handler(data) {
        if (data?.h1?.length > 3) {
          this.disabledButton = true;
        } else {
          this.disabledButton = false;
        }
      },
      deep: true
    }
  },
  methods: {
    async submit() {
      this.$emit("submit", this.editData);
    },

    onChangeErrorState(error: any) {
      this.valid = !error;
    },

    hide() {
      this.$emit("hide", false);
    }
  },

  inheritAttrs: false
});
