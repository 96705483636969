
import { computed, defineComponent, ref } from "vue";
import { useLocations } from "@/hooks/locations/new/useLocations";
import { ITableHeadCell } from "@/components/Table/ts/interfaces/TableStructure";
import AboutLocationModal from "@/components/ui/Modal/Dialog/instances/AboutLocationModal.vue";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import ShadowControl from "@/components/Form/ShadowControl.vue";

import SelectDropdown from "@/components/ui/Dropdown/SelectDropdown.vue";
import AppToolbarTable from "@/components/Table/ts/AppToolbarTable.vue";
import DeleteLocationConfirm from "@/components/Locations/DeleteLocationConfirm.vue";
import CreateLocationModal from "@/components/Locations/CreateLocationModal.vue";
import { TPackageLocation } from "@/hooks/esim/location-packages/types/locationPackage.types";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import BtnContent from "@/components/ui/Buttons/BtnContent.vue";
import { IAboutLocation } from "@/api/interfaces/about-locations/aboutLocation";
import { useAboutLocation } from "@/hooks/locations/useAboutLocation";
import useLocalizations from "@/hooks/localizations/useLocalizations";

export default defineComponent({
  name: "Locations",
  components: {
    AboutLocationModal,
    AppButton,
    BtnContent,
    CreateLocationModal,
    DeleteLocationConfirm,
    AppToolbarTable,
    SelectDropdown,
    ShadowControl,
    PageMainHeading,
    AppTableController,
  },

  setup() {
    const location = useLocations({
      initialSearchQuery: "",
    });
    let {
      fetchAboutLocation,
      actionAboutLocation,
      editData,
    } = useAboutLocation();

    const { localizations } = useLocalizations();

    const parentSelectedTab = computed(
      () => location.tabs.selectedTab.value.title as TPackageLocation
    );
    let modalOpened = ref(false);
    fetchAboutLocation();
    const deleteModalOpened = ref(false);
    const deleteLocation = ref({ icon: "", name: "", id: -1 });

    function startLocationDelete(id: number) {
      const $location = location.locationsList.value
        .map((l) => l)
        .find((location) => location.id === id);

      if ($location) {
        deleteModalOpened.value = true;

        deleteLocation.value = {
          icon: $location.icon,
          name: $location.name,
          id,
        };
      }
    }

    async function deleteConfirm(id: number) {
      await location.deleteLocation(id, parentSelectedTab.value);
      deleteModalOpened.value = false;
      deleteLocation.value = {
        icon: "",
        name: "",
        id: -1,
      };
    }

    function deleteDiscard() {
      deleteModalOpened.value = false;
      deleteLocation.value = { icon: "", name: "", id: -1 };
    }

    async function actionWithLocation(data: IAboutLocation) {
      let success = false;
      if (data.id) {
        success = await actionAboutLocation(data, "patch", data.id);
      } else {
        success = await actionAboutLocation(data, "post");
      }
      if (success) {
        modalOpened.value = false;
      }
    }

    return {
      ...location,
      startLocationDelete,
      deleteLocation,
      deleteModalOpened,
      viewConfiguration: location.viewConfiguration || { page: 1, perPage: 1 },
      parentSelectedTab,
      deleteConfirm,
      deleteDiscard,
      editData,
      modalOpened,
      actionWithLocation,
      localizations,
      pageTitle: computed(() => {
        return location.tabs.selectedTab.value.title;
      }),
    };
  },

  computed: {
    tableHead(): ITableHeadCell[] {
      const { byName, byCreated } = this.currentSort || {};

      return [
        {
          size: "fluid",
          label: "Title",
          id: "location-title",
          sort: {
            keyName: byName.keyName,
            order: byName.order,
          },
        },

        {
          size: "lg",
          label: "Date created",
          id: "data-created",
          rightAlign: true,
          sort: {
            keyName: byCreated.keyName,
            order: byCreated.order,
          },
        },

        {
          size: "md",
          label: "Status",
          id: "location-status",
          rightAlign: true,
        },

        {
          size: "smL",
          label: "Actions",
          id: "location-actions",
          rightAlign: true,
        },
      ];
    },
  },
});
