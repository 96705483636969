import { httpMethodType } from "@/api/interfaces/IApi";
import { computed } from "vue";
import { useStore } from "vuex";

interface editAboutLocation {
    id?: number;
    title?: string;
    description?: string;
    body?: string;
    keywords?: string;
    h1?: string;
    SEO_block?: string;
}

export function useAboutLocation() {
  const store = useStore();

  async function fetchAboutLocation(): Promise<void> {
    await store.dispatch("setAppLoading", true);
    const { success, message, data } = await store.dispatch("fetchAboutLocation");
    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return data;
  }

  async function actionAboutLocation(
    data: editAboutLocation,
    method: httpMethodType,
    id?: number
  ): Promise<boolean> {
    await store.dispatch("setAppLoading", true);
    const { success, message } = await store.dispatch("addEditAboutLocation", {
      data,
      method,
      id
    });

    if (!success) {
      await store.dispatch("showErrorNotification", message);
    }

    await store.dispatch("setAppLoading", false);
    return success;
  }

  return {
    actionAboutLocation,
    fetchAboutLocation,
    editData: computed(() => store.getters.aboutLocation),
  };
}
