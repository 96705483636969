import { computed, ComputedRef } from "vue";
import { TLocationKeys } from "@/store/locations";
import {
  locationTypeToSingle,
  packageLocationTypes,
  TPackageLocationSingle
} from "@/hooks/esim/location-packages/types/locationPackage.types";
import { ILocationsCtx } from "@/hooks/locations/new/useLocations";

export function locationType<T extends TLocationKeys>(ctx: ILocationsCtx<T>) {
  const { store } = ctx;
  const { tabs, selectedTab } = ctx.tabs;

  const currentLocation: ComputedRef<TLocationKeys> = computed(
    () =>
      (selectedTab.value.value as TLocationKeys) ||
      tabs[0] ||
      packageLocationTypes.countries
  );

  const currentLocationName: ComputedRef<TPackageLocationSingle> = computed(
    () => {
      return locationTypeToSingle[
        currentLocation.value
      ] as TPackageLocationSingle;
    }
  );

  const locations = computed(<T extends TLocationKeys>() => {
    return store.getters.getLocations(selectedTab.value.value);
  });

  return { currentLocation, currentLocationName, locations };
}
