
import { defineComponent } from "@vue/runtime-core";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import LocalizationsTable from "@/components/Table/ts/instances/localizations/LocalizationsTable.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import BtnContent from "@/components/ui/Buttons/BtnContent.vue";
import DeleteBrandDialog from "@/components/ui/Modal/Dialog/instances/DeleteBrandDialog.vue";
import CreateLocalizationModal from "@/components/Localizations/CreateLocalizationModal.vue";
import { useUserTable } from "@/hooks/tables/modules/usersTable/usersTable";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
import useLocalizations from "@/hooks/localizations/useLocalizations";

export default defineComponent({
  name: "Localizations",
  components: {
    DeleteBrandDialog,
    CreateLocalizationModal,
    BtnContent,
    AppButton,
    PageMainHeading,
    AppTableController,
    LocalizationsTable
  },

  setup() {
    return {
      ...useUserTable(),
      ...useLocalizations()
    };
  }
});
