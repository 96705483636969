import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pt-4" }
const _hoisted_2 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AutosizeTextarea = _resolveComponent("AutosizeTextarea")!
  const _component_AppInputsValidationProvider = _resolveComponent("AppInputsValidationProvider")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    title: _ctx.modalTitle,
    opened: _ctx.opened,
    onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('hide')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "mt-2 pt-2",
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_AppInputsValidationProvider, { onChangeErrorState: _ctx.changeErrorState }, {
          default: _withCtx(() => [
            _createVNode(_component_AppInput, {
              id: "carrier-title",
              modelValue: _ctx.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
              "input-type": "material",
              required: true,
              "validation-type": "notEmptyString",
              label: "Title"
            }, null, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_AutosizeTextarea, {
                id: "carrier-description",
                modelValue: _ctx.description,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.description) = $event)),
                "input-type": "material",
                required: true,
                "validation-type": "notEmptyString",
                label: "Description",
                type: "text",
                "is-textarea": true,
                "is-auto-size": true,
                "textarea-required": true
              }, null, 8, ["modelValue"])
            ])
          ]),
          _: 1
        }, 8, ["onChangeErrorState"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AppButton, {
            disabled: !_ctx.valid,
            type: "secondary",
            size: "xl",
            loading: _ctx.loading,
            onClick: _ctx.submitHandler
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.buttonLabel), 1)
            ]),
            _: 1
          }, 8, ["disabled", "loading", "onClick"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "opened"]))
}